$(document).on('turbolinks:load',
    function () {
      var district_select = $("#patient_district");
      var sector_select = $("#patient_sector");
      var cell_select = $("#patient_cell");
      var village_select = $("#patient_village");

      district_select.prop("disabled", true);
      sector_select.prop("disabled", true);
      cell_select.prop("disabled", true);
      village_select.prop("disabled", true);

      // when the province changes
      $("#patient_province").change(function () {
        // reset all dependent children and grand-children:
        district_select.empty().append('<option value="">Select</option>').prop("disabled", true);
        sector_select.empty().append('<option value="">Select</option>').prop("disabled", true);
        cell_select.empty().append('<option value="">Select</option>').prop("disabled", true);
        village_select.empty().append('<option value="">Select</option>').prop("disabled", true);

        var selectedProvince = $(this).children("option:selected").val();

        $.ajax({
          url: '/province_districts/' + selectedProvince,
          type: 'get',
          success: function (data) {
            district_select.prop("disabled", false).empty().append('<option value="">Select</option>');
            for (var i = 0; i < data.length; i++) {
              district_select.append('<option value="' + data[i] + '">' + data[i] + '</option>');
            }
          }
        });
      })

      // when the district changes
      district_select.change(function () {
        cell_select.empty().append('<option value="">Select</option>').prop("disabled", true);
        var selectedDistrict = $(this).children("option:selected").val();
        var url = '/district_sectors/' + selectedDistrict;
        $.ajax({
          url: url,
          type: 'get',
          success: function (data) {
            sector_select.prop("disabled", false).empty().append('<option value="">Select</option>');
            for (var i = 0; i < data.length; i++) {
              sector_select.append('<option value="' + data[i] + '">' + data[i] + '</option>');
            }
          }
        });
      })

      //when the sector changes
      sector_select.change(function () {
        var selected_district = district_select.val();
        var selectedSector = $(this).children("option:selected").val();
        var url = '/sector_cells/?district=' + selected_district + '&sector=' + selectedSector;
        $.ajax({
          url: url,
          type: 'get',
          success: function (data) {
            cell_select.prop("disabled", false).empty().append('<option value="">Select</option>');

            for (var i = 0; i < data.length; i++) {
              $('#patient_cell').append('<option value="' + data[i] + '">' + data[i] + '</option>');
            }
          }
        });
      })

      //when the cell changes
      cell_select.change(function () {
        var selected_district = district_select.val();
        var selected_sector = sector_select.val();
        var selected_cell = cell_select.val();

        // $(this).children("option:selected").val();
        var url = '/cell_villages/?district=' + selected_district + '&sector=' + selected_sector + '&cell=' + selected_cell;
        $.ajax({
          url: url,
          type: 'get',
          success: function (data) {
            village_select.prop("disabled", false).empty().append('<option value="">Select</option>');

            for (var i = 0; i < data.length; i++) {
              village_select.append('<option value="' + data[i] + '">' + data[i] + '</option>');
            }
          }
        });
      })
    })