$(document).on('turbolinks:load', function() {

  let year_of_diagnosis = $('#year_of_diagnosis').val();
  // console.log(year_of_diagnosis);
  // today's year - year of diagnosis
    let age = new Date().getFullYear() - year_of_diagnosis;
    $(".albumin_creatinine_ratio_alert").hide();
    if( new Date().getFullYear() - year_of_diagnosis >= 5){
        $(".albumin_creatinine_ratio_alert").show();
    }


  // Nephropathy
  $("#examined_for_nephropathy").change(function() {
    if($(this).val() == "Yes") {
        $(".albumine_creatinine_ratio, .nephropathy").prop("hidden", false);
    } else {
      $("#albumine_creatinine_ratio, #nephropathy").val("");
      $(".albumine_creatinine_ratio, .nephropathy").prop("hidden", true);
    }
  })

  // === Retinopathy 
  // == TO DO: Clean up the below code = DRY it up.
  $("#examined_for_retinopathy_in_past_12_months").change(function() {
    if ($("#examined_for_retinopathy_in_past_12_months :selected").val() == "Yes") {
      $(".retinopathy").show();
      $("#retinopathy").val("");
    } else {
      $("#retinopathy").val("");
      $(".retinopathy").hide();
      $(".eye_care_treatment").prop("hidden", true)
    }
  });

  $("#examined_for_retinopathy_in_past_12_months").change(function() {
    if($(this).val() == "Yes") {
      $(".date_of_retinopathy_screening, .retinopathy").prop("hidden", false);
      $(".referred_to_screening_for_retinopathy").prop("hidden", true);
    } else {
      $(".date_of_retinopathy_screening, .retinopathy").prop("hidden", true);
      $(".referred_to_screening_for_retinopathy").prop("hidden", false);
      $(".eye_care_treatment").prop("hidden", true)
    }
  })

      // ===Neuropathy
      $("#examined_for_neuropathy_in_past_12_months").change(function() {
        if ($("#examined_for_neuropathy_in_past_12_months :selected").val() == "Yes") {
          $(".neuropathy").show();
          $("#neuropathy").val("");
        } else {
          $("#neuropathy").val("");
          $(".neuropathy").hide();
        }
      });

    //Prompt the user to mention if the treatment to retinopathy has been given
    $("#retinopathy").change(function(){
      var selected_value = $(this).val();
      if(selected_value == "Simple" || selected_value == "Modest" || selected_value == "Severe"  ) {
        $(".eye_care_treatment").prop("hidden", false);
      } else {
        $(".eye_care_treatment").prop("hidden", true)
      }
    })

    

    // Neuropathy, date_of_neuropathy_screening
    $(".date_of_neuropathy_screening, .neuropathy").prop("hidden", true);
    $("#examined_for_neuropathy").change(function() {
      if($(this).val() == "Yes") {
        $(".date_of_neuropathy_screening, .neuropathy").prop("hidden", false);
      } else {
        $(".date_of_retinopathy_screening, .neuropathy").prop("hidden", true);
      }
    });

    // Smoking 
    $(".smoking_frequency, .what_do_you_smoke, .other_smoke_type").prop("hidden", true);
    $("#do_you_smoke").change(function(){
      let do_you_smoke = $("#do_you_smoke").val();
      if (do_you_smoke == "true") {
        $(".smoking_frequency, .what_do_you_smoke").prop("hidden", false);
      }
      else {
        $(".smoking_frequency, .what_do_you_smoke").prop("hidden", true);
      }
    })



    $('#what_do_you_smoke').change(function() {
      if($(this).val() == 'other') {
        $(".other_smoke_type").prop("hidden", false);
        $('.other_smoke_type').attr('required', true);
      } else {
        $('.other_smoke_type').prop('hidden', true);
        $('.other_smoke_type').attr('required', false);
      }
    })




    // Drinking


    
    $(".how_often_do_you_drink_when_you_drink, .how_much_alcohol_do_you_drink, .alcohol_type, .other_alcohol_type").prop("hidden", true);
    $("#do_you_drink_alcohol").change(function(){
      let do_you_drink = $("#do_you_drink_alcohol").val();
      
      
      if (do_you_drink == "true") {
        $(".how_often_do_you_drink_when_you_drink, .how_much_alcohol_do_you_drink, .alcohol_type").prop("hidden", false);
        $('.how_often_do_you_drink_when_you_drink').show();
        $('.how_much_alcohol_do_you_drink').show();
        $('#how_much_alcohol_do_you_drink').attr('required', true);
      }
      else {
        $(".how_often_do_you_drink_when_you_drink, .how_much_alcohol_do_you_drink, .alcohol_type").prop("hidden", true);
        
        $('#how_much_alcohol_do_you_drink').attr('required', false);
        $('.how_much_alcohol_do_you_drink').hide();
        
      }
    })
    
    // ****************************************************************** //
    // Script to show/hide other text field based on the value of the selected value of alcohol type  //

    $('#alcohol_type').change(function() {
      if($(this).val() == 'other') {
        $(".other_alcohol_type").prop("hidden", false);
        $('.other_alcohol_type').attr('required', true);
      } else {
        $('.other_alcohol_type').prop('hidden', true);
        $('.other_alcohol_type').attr('required', false);
      }
    }
    )

    // ***************************************************************** //

    // Physical activity
    $(".physical_activity_frequency, .physical_activity_time, .physical_activity_type").prop("hidden", true);
    $("#physical_activity").change(function(){
      let physical_activity = $("#physical_activity").val();
      if (physical_activity == "true") {
        $(".physical_activity_frequency, .physical_activity_time, .physical_activity_type").prop("hidden", false);
        $('#physical_activity_type').show();
        $('#physical_activity_frequency').show();
        $('#physical_activity_time').show();
        $('#physical_activity_type').attr('required', true);
        $('#physical_activity_frequency').attr('required', true);
        $('#physical_activity_time').attr('required', true);
      }
      else {
        $(".physical_activity_frequency, .physical_activity_time, .physical_activity_type").prop("hidden", true);
        $('#physical_activity_type').hide();
        $('#physical_activity_frequency').hide();
        $('#physical_activity_time').hide();
        $('#physical_activity_type').attr('required', false);
        $('#physical_activity_frequency').attr('required', false);
        $('#physical_activity_time').attr('required', false);
      }
    })

    // Nutrition
    $('#meals_per_day').change(function() {
      if($(this).val() == 'Yes') {
        $('#snacks_per_day').show();
        $('#snacks_per_day').attr('required', true);
      } else {
        $('#snacks_per_day').hide();
        $('#snacks_per_day').attr('required', false);
      }
    });

  //===== Mental health 
  function getSum(total, num) {
    return total + Math.round(num);
  }

  $(".paid_mental_health_scale").change(function(){
    var selected_PAID_values = $('.paid_mental_health_scale option:selected').toArray().map(item => item.value);
    var mental_health_score = (selected_PAID_values).reduce(getSum, 0);
    $("#mental_health_score").val(mental_health_score);
      // less than 10: low
      // between 10 and 16: mild
      // if value is > 16 => severe
      var paid_scale_interpretation = function(value){
        if (value <= 10) {
          return "Low distress"
        } 
        else if(value > 10 && value < 16){
          return "Mild distress"
        }
        else if(value >= 16){
          return "Severe distress"
        }
      }
      
      $("#diabetes_related_distress").val(paid_scale_interpretation(mental_health_score));
    })

    // Education report
    // ==============
    // == Currently in school
    //== Consultation currently in school change: if yes, ask school level and year. By default they should be hidden. unless this is an existing record that we are editing
    $(".school_level, .year_of_education, .reason_why_not_in_school, .school_dropout").prop("hidden", true);
    $("#currently_in_school").change(function() {
      var if_in_school = $("#currently_in_school :selected").val();
      if (if_in_school == "true") {
        $(".school_level, .year_of_education").prop("hidden", false);
        $(".reason_why_not_in_school, .school_dropout").prop("hidden", true);
      } else {
        $(".school_level, .year_of_education").prop("hidden", true);
        $(".reason_why_not_in_school, .school_dropout").prop("hidden", false);
      }
    });
    $(".reason_for_dropout").prop("hidden", true);
    $("#school_dropout").change(function(){
      // alert($(this).val())
      if ($(this).val() == "true") {
        $(".reason_for_dropout").prop("hidden", false)
      } else {
        $(".reason_for_dropout").prop("hidden", true)
      }
    })


    // Employment report
    $(".type_of_job").prop("hidden", true);
    $("#do_you_have_a_job").change(function(){
      // alert($(this).val())
      if ($(this).val() == "true") {
        $(".type_of_job").prop("hidden", false)
      } else {
        $(".type_of_job").prop("hidden", true)
      }
    })
  })