$(document).on('turbolinks:load', function() {
    // initialize datatables on patients/index
    table = $('#patients-datatable').dataTable({
        "processing": true,
        "serverSide": true,
        "destroy": true,
        "stateSave": true,
        "bLengthChange": true,
        "rowReorder": true,
        "searching": true,
        "dom": "Bfrtip",
        "language": { "search": '', "searchPlaceholder": "Type to search" },
        "pageLength": 10,
        "pagingType": "full_numbers",
        // "order": [[0, "asc"]],
        "ajax": {
            "url": $('#patients-datatable').data('source')
        },
        "columns": [
            { "data": "fullname" },
            { "data": "gender" },
            { "data": "age" },
            { "data": "unique_id_number" },
            { "data": "father" },
            { "data": "mother" },
            { "data": "status" },
            { "data": "personal_phone" },
            { "data": "place_of_care" },
            { "data": "registration_date" },
            { "data": "edit" },
            { "data": "new_consultation" }
            ] 
    });

    $('.dataTables_filter input').addClass('dataTablesFilterInput'); 
    // end initialization of datatables


    $("#has_district_hospital_id").change(function(){
        if ($("#has_district_hospital_id :selected").val() == 'true') {
            $(".district_hospital_id").show()
            $("#district_hospital_id").val("");
        } else {
            $(".district_hospital_id").hide()

        }
    })
    $("#year_of_diagnosis_box").change(function(){
        if ($("#year_of_diagnosis_box").is(':checked')) {
            $("#year_of_diagnosis").prop('disabled', true);
            $("#year_of_diagnosis").val("unknown");
        } else {
            $("#year_of_diagnosis").prop('disabled', false);
        }
    })
})