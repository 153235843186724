// initiate select2 on all select options
$(document).on('turbolinks:load', function() {
    // ====== No more than 3 digits for weight, height,
    $("#weight, #height, #systolic, #diastolic").keyup(function(e) {
        e.preventDefault();
        var value = $(this).val();
        if(value.length >= 4) {
            new_value = value.substring(0, 3);
            $(this).val(new_value);
        }
    });

    // ===== Select2 library
    $('.select2').select2({
        theme: "bootstrap-5"
    });

    // ==== Hospitalization
    $(".reason_for_hospitalization, .reason_for_hospitalization_other").hide();
    $("#hospitalizations_cases_in_past_3_months").change(function() {
        if ($("#hospitalizations_cases_in_past_3_months :selected").val() == "0") {
            $("#reason_for_hospitalization").val("");
            $(".reason_for_hospitalization").hide();
        } else {
            $(".reason_for_hospitalization").show();
            $("#reason_for_hospitalization").val("");
        }
    });
    $("#reason_for_hospitalization").change(function() {
        if ($("#reason_for_hospitalization :selected").val() == "Other") {
            $(".reason_for_hospitalization_other").show();
            $("#reason_for_hospitalization").val($("#reason_for_hospitalization_other").val());
        } else {
            $(".reason_for_hospitalization_other").hide();
        }
    });
    //==== Distance to hospital converter
    $("#distance_to_hospital_hours, #distance_to_hospital_minutes ").keyup(function() {
        var hours = $("#distance_to_hospital_hours").val();
        var minutes = $("#distance_to_hospital_minutes").val();
        var total_minutes = (Number(hours * 60) + Number(minutes));
        $("#total_distance_in_minutes").val(total_minutes);

        // console.log(total_minutes);
    });



    // ======== blood glusose level
    $("#blood_glucose_level_in_mg_per_deciliter, #blood_glucose_type").keyup(function(e){
        e.preventDefault();
        let glucose_in_mg = $("#blood_glucose_level_in_mg_per_deciliter").val();
        let glucose_type = $("#blood_glucose_type :selected").val();
        if (glucose_in_mg < 0 || glucose_in_mg > 600) {
            $("#glucose_error_message").text("Please enter between 0 - 600").css("color", "red");
            $("#blood_glucose_level_in_mmol_per_mol").val("")
        }
        else {
            $("#glucose_error_message").text("");
            let glucose_in_mmol = (glucose_in_mg / 18);
            $("#blood_glucose_level_in_mmol_per_mol").val(glucose_in_mmol);
            if (glucose_type == "Random/post-prandial") {
                if (glucose_in_mg >= 0 && glucose_in_mg <= 90.09 && glucose_in_mg != ""&& glucose_in_mg != null) {
                    $("#blood_glucose_level_in_mg_per_deciliter").css('box-shadow', '2px 2px 2px 2px red');
                    $("#glucose_alert_message").text("Low").css('color', 'red');
                }
                else if (glucose_in_mg >= 90.09 && glucose_in_mg <= 162.16 && glucose_in_mg != ""&& glucose_in_mg != null) {
                    $("#blood_glucose_level_in_mg_per_deciliter").css('box-shadow', '2px 2px 2px 2px green');
                    $("#glucose_alert_message").text("normal").css('color', 'green');
                }
                else if (glucose_in_mg >= 162.16 && glucose_in_mg <= 200 && glucose_in_mg != ""&& glucose_in_mg != null) {
                    $("#blood_glucose_level_in_mg_per_deciliter").css('box-shadow', '2px 2px 2px 2px yellow');
                    $("#glucose_alert_message").text("above target").css('color', 'yellow');
                }
                else if (glucose_in_mg > 200 && glucose_in_mg != ""&& glucose_in_mg != null) {
                    $("#blood_glucose_level_in_mg_per_deciliter").css('box-shadow', '2px 2px 2px 2px red');
                    $("#glucose_alert_message").text("Too high").css('color', 'red');
                }
                else {
                    $("#blood_glucose_level_in_mg_per_deciliter").css('box-shadow', 'none');
                    $("#glucose_alert_message").text("");
                }
            //    Alerts for blood glucose level in mmol/l
                if (glucose_in_mmol >= 0 && glucose_in_mmol <= 5 && glucose_in_mmol != ""&& glucose_in_mmol != null) {
                    $("#blood_glucose_level_in_mmol_per_mol").css('box-shadow', '2px 2px 2px 2px red');
                    $("#glucose_alert_message_mmol").text("Low").css('color', 'red');
                }
                else if (glucose_in_mmol >= 5 && glucose_in_mmol <= 9 && glucose_in_mmol != ""&& glucose_in_mmol != null) {
                    $("#blood_glucose_level_in_mmol_per_mol").css('box-shadow', '2px 2px 2px 2px green');
                    $("#glucose_alert_message_mmol").text("normal").css('color', 'green');
                }
                else if (glucose_in_mmol >= 9 && glucose_in_mmol <= 11 && glucose_in_mmol != ""&& glucose_in_mmol != null) {
                    $("#blood_glucose_level_in_mmol_per_mol").css('box-shadow', '2px 2px 2px 2px yellow');
                    $("#glucose_alert_message_mmol").text("above target").css('color', 'yellow');
                }
                else if (glucose_in_mmol > 11 && glucose_in_mmol != ""&& glucose_in_mmol != null) {
                    $("#blood_glucose_level_in_mmol_per_mol").css('box-shadow', '2px 2px 2px 2px red');
                    $("#glucose_alert_message_mmol").text("Too high").css('color', 'red');
                }
                else {
                    $("#blood_glucose_level_in_mmol_per_mol").css('box-shadow', 'none');
                    $("#glucose_alert_message_mmol").text("");
                }
            }
            else if (glucose_type == "Fasting/pre-prandial") {
                if (glucose_in_mg >= 0 && glucose_in_mg <= 72.07 && glucose_in_mg != ""&& glucose_in_mg != null) {
                    $("#blood_glucose_level_in_mg_per_deciliter").css('box-shadow', '2px 2px 2px 2px red');
                    $("#glucose_alert_message").text("Low").css('color', 'red');
                }
                else if (glucose_in_mg >= 72.07 && glucose_in_mg <= 123.13 && glucose_in_mg != ""&& glucose_in_mg != null) {
                    $("#blood_glucose_level_in_mg_per_deciliter").css('box-shadow', '2px 2px 2px 2px green');
                    $("#glucose_alert_message").text("normal").css('color', 'green');
                }
                else if (glucose_in_mg > 126.13 && glucose_in_mg != ""&& glucose_in_mg != null) {
                    $("#blood_glucose_level_in_mg_per_deciliter").css('box-shadow', '2px 2px 2px 2px red');
                    $("#glucose_alert_message").text("Too high").css('color', 'red');
                }
                else {
                    $("#blood_glucose_level_in_mg_per_deciliter").css('box-shadow', 'none');
                    $("#glucose_alert_message").text("");
                }
            //    Alerts for blood glucose level in mmol/l
                if (glucose_in_mmol >= 0 && glucose_in_mmol <= 4 && glucose_in_mmol != ""&& glucose_in_mmol != null) {
                    $("#blood_glucose_level_in_mmol_per_mol").css('box-shadow', '2px 2px 2px 2px red');
                    $("#glucose_alert_message_mmol").text("Low").css('color', 'red');
                }
                else if (glucose_in_mmol >= 4 && glucose_in_mmol <= 7 && glucose_in_mmol != ""&& glucose_in_mmol != null) {
                    $("#blood_glucose_level_in_mmol_per_mol").css('box-shadow', '2px 2px 2px 2px green');
                    $("#glucose_alert_message_mmol").text("normal").css('color', 'green');
                }
                else if (glucose_in_mmol > 7 && glucose_in_mmol != ""&& glucose_in_mmol != null) {
                    $("#blood_glucose_level_in_mmol_per_mol").css('box-shadow', '2px 2px 2px 2px red');
                    $("#glucose_alert_message_mmol").text("Too high").css('color', 'red');
                }
                else {
                    $("#blood_glucose_level_in_mmol_per_mol").css('box-shadow', 'none');
                    $("#glucose_alert_message_mmol").text("");
                }
            }
            else {
                $("#blood_glucose_level_in_mg_per_deciliter").css('box-shadow', 'none');
                $("#glucose_alert_message").text("");
                $("#blood_glucose_level_in_mmol_per_mol").css('box-shadow', 'none');
                $("#glucose_alert_message_mmol").text("");
            }
        }

    })

      // Hypoglycemia
      $("#hypoglycaemia_cases_in_past_3_months").change(function() {
        if ($("#hypoglycaemia_cases_in_past_3_months selected").val() == "0") {
            $("#person_who_helped_manage_hypoglycemia").val("");
            $(".person_who_helped_manage_hypoglycemia").hide();
        } else {
            $(".person_who_helped_manage_hypoglycemia").show();
            $("#person_who_helped_manage_hypoglycemia").val("");
        }
    });

    // Nephropathy and Albumine-creatinine ratio
    $("#albumine_creatinine_ratio").keyup(function() {
        var acr = $("#albumine_creatinine_ratio").val();
        var nephropathy = "";
        if ((acr.length < 1)) {
            $("#nephropathy").val("");
        } else {
            if (acr <= 30) {
                nephropathy = "No";
            } else if ((acr > 30) && (acr <= 300)) {
                nephropathy = "Microalbuminuria (" + acr + ")";
            } else if (acr > 300) {
                nephropathy = "Macroalbuminuria (" + acr + ")";
            }
            $("#nephropathy").val(nephropathy);
        }
    });

      //===== Glucometer. 
    //Hide some fields.  
    $(".glucometer_works, .why_no_glucometer, .why_glucometer_does_not_work, .why_no_glucometer_other").hide();
    //Does the patient have glucometer?
    $("#access_to_glucometer").change(function() {
        if ($("#access_to_glucometer :selected").val() == "true") {
            $(".glucometer_works").show();
            $("#glucometer_works").val("");
            $(".why_no_glucometer").hide();
        } else {
            $(".glucometer_works").hide();
            $(".why_no_glucometer").show();
            $("#why_no_glucometer").val("");
            $(".why_glucometer_does_not_work").hide()
        }
    });
    $("#why_no_glucometer").change(function() {
        if ($("#why_no_glucometer :selected").val() == "Other") {
            $(".why_no_glucometer_other").show();
            $("#why_no_glucometer_other").val("");
        } else {
            $(".why_no_glucometer_other").hide();
            $("#why_no_glucometer_other").val("");
            $("#why_no_glucometer").val($("#why_no_glucometer").val());
        }
    });

    //=========== HB1AC conversion 
    function convert_hba1c() {
        var hba1c_in_percentage = $("#hba1c_in_percentage").val();
        // var hba1c_in_mmol_per_mol = ((10.93 * hba1c_in_percentage) - 23.5).toPrecision(2);
        var hba1c_in_mmol_per_mol = ((10.93 * hba1c_in_percentage) - 23.5).toFixed(2);
        if ((hba1c_in_percentage.length > 0)) {
            $("#hba1c_in_mmol_per_mol").val(hba1c_in_mmol_per_mol);
        } else {
            $("#hba1c_in_mmol_per_mol").val("");
        }
    }

    // compute hba1c on page load
    // convert_hba1c();
    // compute hba1c on keyup for hba1c in %
    $("#hba1c_in_percentage").keyup(function() {

        if (
            ($("#hba1c_in_percentage").val() < 1) || 
            ($("#hba1c_in_percentage").val() > 16) ) {
            $(".hba1c_error_message").text("Value should be from 1 to 16")
    } 

    else {
        convert_hba1c();
        $(".hba1c_error_message").text("")

    }
})

    //========== Does the glucometer work? 
    $(".glucometer_works").change(function() {
        if ($("#glucometer_works :selected").val() == "true") {
            $(".why_no_glucometer").hide();
            $(".why_glucometer_does_not_work").hide();
        } else {
            $(".why_glucometer_does_not_work").show();
            $("#why_glucometer_does_not_work").val("");
        }
    });

    //=========== BMI calculator
    function calculate_bmi(weight_in_kg, height_in_cm) {
        var height_in_meters = height_in_cm / 100;
        bmi = (weight_in_kg / (height_in_meters * height_in_meters)).toPrecision(3);

        if (weight_in_kg && height_in_meters && !isNaN(bmi)) {
            $("#bmi").val(bmi);
        } else {
            $("#bmi").val("");
        }
        // Interpretation
        // ===== To Do
        // if (bmi <= 18.4) {
        //     $("#bmi").val(bmi + " (Underweight)").css('color', 'red');
        // } else if (bmi >= 18.5 && bmi <= 24.9) {
        //     $("#bmi").val(bmi + " (Healthy)").css('color', 'green');
        // } else if (bmi >= 25 && bmi <= 29.9) {
        //     $("#bmi").val(bmi + " (Overweight)").css('color', 'red');
        // } else if (bmi >= 30) {
        //     $("#bmi").val(bmi + " (Obese)").css('color', 'red');
        // }
    }

    // calculate bmi on key change
    $("#weight, #height").on("keyup", function() {
        var weight = $("#weight").val();
        var height = $("#height").val();
        if ((weight.length > 0) && (height.length > 0)) {
            calculate_bmi(weight, height)
        } 
    });
    
    //============== End of BMI calculator

    // =========== Blood pressure calculation
    function calculate_blood_pressure(systolic, diastolic) {
        var blood_pressure = ""
        if (systolic.length > 0 && diastolic.length > 0) {
            blood_pressure = systolic + "/" + diastolic + "mmHg";
        } 
        return blood_pressure;
    }

    // Blood pressure validations
    $("#systolic, #diastolic").keyup(function() {
        // ===== Systolic and Diastolic validation to be less than 240
        var systolic = $("#systolic").val();
        var diastolic = $("#diastolic").val();
        var errMess = $("#errMess");
        $("#blood_pressure").val(calculate_blood_pressure(systolic, diastolic));

        if ((systolic > 120 && systolic < 240) || (diastolic > 80 && diastolic < 240)) {
            errMess.text("Patient has hypertension");
            errMess.css('color', 'red');
        }

        else if ((systolic > 240) || (diastolic > 240)) {
            errMess.text("Values should be less than 240");
            errMess.css('color', '#930d40');
        } 
        else {
            errMess.text("");
        }

    })


    // =========== Treatment Regimen Dynamic units

    $("#pre_bedtime").change(function() {
        if ($("#pre_bedtime").val() == "Oral antidiabetic drugs") {
            $("#pre_bedtime_units").text("mg");
        } else {
            $("#pre_bedtime_units").text("units");
        }
    })

    // calculate blood pressure as soon as the page loads
    // $("#systolic, #diastolic").keyup(function() {
        // var systolic = $("#systolic").val();
        // var diastolic = $("#diastolic").val();
        // var message_color = "black";
        // if ((systolic > 80) || (diastolic > 80)) { 
        //     message_color = "red";
        // }
        // $("#blood_pressure").html(calculate_blood_pressure(systolic, diastolic)).css('color', message_color);
    // })

    // ======= END of blood pressure calculation


    //cholesterol validation
    $("#cholesterol").on("keyup", function() {
        var cholesterol_in = $("#cholesterol").val();
        if (cholesterol_in < 200) {

            $("#cholesterol").css('box-shadow', '2px 2px 2px 2px green')
            $("#cholesterol_alert").text("Cholesterol is normal ").css('color', 'green')

        }
        if (cholesterol_in > 200 && cholesterol_in < 239) {
            $("#cholesterol").css('box-shadow', '2px 2px 2px 2px orange')
            $("#cholesterol_alert").text("Choresterol is borderline high level ").css('color', 'orange')
        }
        if (cholesterol_in > 240) {
            $("#cholesterol").css('box-shadow', '2px 2px 2px 2px red')
            $("#cholesterol_alert").text("Cholesterol is on high level ").css('color', 'red')
        }
    })

    $("#number_of_self_injections_per_day").on("keyup", function() {
        let number_of_self_injections_per_day = $("#number_of_self_injections_per_day").val();
        if (number_of_self_injections_per_day.length > 1) {
            $("#number_of_self_injections_per_day").val(number_of_self_injections_per_day[0]);
        }
        if (number_of_self_injections_per_day < 0) {
            $("#number_of_self_injections_per_day").val(0);
        }
    });
});